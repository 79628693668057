import { styled } from "styled-components";
import { StyledProps } from "../../common/types";

export const CardStyled = styled.div<StyledProps>`
  max-width: 384px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  background: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  border-style: solid !important;
  border-width: ${({ borderWidth }) => borderWidth || "1px"};
  border-color: ${({ borderColor }) => borderColor || "#e5e7eb"};
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};
  justify-content: space-between !important;
  display: flex !important;
  flex-direction: column !important;
  text-decoration: none !important;
  color: #000;

  &:hover {
    background: ${({ hoverBackgroundColor }) =>
      hoverBackgroundColor || "#ffffff"};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor || "#9ca3af"};
  }

  > img {
    max-width: 100% !important;
    height: auto !important;
    padding: 32px;
    border-top-left-radius: ${({ borderRadius }) => borderRadius};
    border-top-right-radius: ${({ borderRadius }) => borderRadius};
  }

  > div {
    padding: 0 10px 10px;
    @media (min-width: 750px) {
      padding: 0 20px 20px;
    }
  }
`;

export const CardLink = styled.a`
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    opacity: 0.8;
  }
`;

export const ImageWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  > img {
    max-width: 100% !important;
    height: 180px !important;

    padding: 32px;
    border-top-left-radius: ${({ borderRadius }) => borderRadius};
    border-top-right-radius: ${({ borderRadius }) => borderRadius};
    @media (min-width: 750px) {
      height: 220px !important;
    }
  }
  @media (min-width: 750px) {
    aspect-ratio: 1/1;
    height: auto !important;
  }
`;

export const ProductNameStyled = styled.div<StyledProps>`
  color: ${({ color }) => color || "#111827"} !important;
  font-size: 14px !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  @media (min-width: 750px) {
    font-size: ${({ fontSize }) => fontSize || "16px"} !important;
    min-height: ${({ height }) => height || "auto"} !important;
  }
`;

export const ProductValueStyled = styled.div<StyledProps>`
  color: ${({ color }) => color || "#111827"} !important;
  font-size: 14px !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  display: inline;
  @media (min-width: 750px) {
    font-size: ${({ fontSize }) => fontSize || "16px"} !important;
  }
`;

export const QuantityWrapper = styled.div`
  border-right: 1px solid black;
  border-left: 1px solid black;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const QuantityValueStyled = styled.input<StyledProps>`
  text-align: center;
  color: ${({ color }) => color || "#111827"} !important;
  font-size: 14px !important;
  width: 40px;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  display: inline;
  margin: 0px 8px;
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
  @media (min-width: 750px) {
    font-size: ${({ fontSize }) => fontSize || "16px"} !important;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: start;
`;

export const CommentTextWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const ApplicationsWrapper = styled.div<StyledProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || "#b4b4b4"};
  padding: 8px;
  border: 1px solid ${({ borderColor }) => borderColor || "#b4b4b4"};
  margin-top: ${({ marginTop }) => marginTop}px;
  display: ${({ hidden }) => (hidden ? "none" : "block")};
  @media (min-width: 750px) {
    min-height: 105px;
  }
`;

export const AddToCartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 12px;
`;

export const IncreaseDecreaseButton = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  align-items: center;
  background-color: none !important;
  &:disabled {
    background-color: none !important;
  }
`;

export const AddToCartButton = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px 16px !important;
  margin-left: 12px !important;
  border-radius: 8px !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "#1d4ed8"} !important;
  color: ${({ color }) => color || "#ffffff"} !important;
  font-size: ${({ fontSize }) => fontSize || "14px"} !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  width: auto !important;
  height: 32px !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 10em inset;
    background-color: #969696;
  }

  &:disabled {
    background-color: #cdcdcd !important;
    cursor: not-allowed !important;
    &:hover {
      box-shadow: none;
    }
  }

  @media (max-width: 980px) {
    margin-left: 0 !important;
    width: 100% !important;
    justify-content: center !important;
  }
`;
