import { styled } from "styled-components";
import { StyledProps } from "../../common/types";

export const HeaderStyled = styled.header<StyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 40px;
  @media (max-width: 1025px) {
    padding: 20px 10px;
  }
  height: auto;
  min-height: 40px;
  max-height: 800px;
  @media (min-width: 750px) {
    height: auto;
  }
`;

export const HeaderTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const HeaderText = styled.div`
  width: 100%;
  max-width: 1240px;
  color: white;
`;
