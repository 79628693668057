import { FC, useEffect, useRef, useState } from "react";
import Card from "../Card";
import { ProductsListProps } from "./types";
import EmptyStateComponent from "../EmptyStateComponent";
import { Product } from "../../common/types";
import { ProductsListStyled } from "./styled";

const ProductsList: FC<ProductsListProps> = ({
  products,
  perPage,
  count,
  setCount,
  productCardConfig,
  collapsed,
  setCollapsed,
  addToCart,
  currency,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const loaderRef = useRef(null);
  const [productsList, setProductList] = useState<Product[]>([]);

  useEffect(() => {
    if (count === perPage) {
      ref.current?.scroll({
        top: 0,
      });
    }
    setProductList(products?.slice(0, count));
  }, [products, count]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setCount((prev) => (products?.length > prev ? prev + perPage : prev));
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    if (ref.current) {
      ref.current?.addEventListener("scroll", () => {
        if (!collapsed) {
          setCollapsed(true);
        }
      });
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
      if (ref.current) {
        ref.current?.removeEventListener("scroll", () => setCollapsed(false));
      }
    };
  });

  return (
    <>
      {!!productsList?.length && (
        <ProductsListStyled ref={ref}>
          {productsList?.map((item, index) => (
            <Card
              key={item.productUrl + index}
              product={item}
              productCardConfig={productCardConfig}
              addToCart={addToCart}
              currency={currency}
            />
          ))}
          <div ref={loaderRef}></div>
        </ProductsListStyled>
      )}
      {!productsList?.length && (
        <EmptyStateComponent
          color={productCardConfig?.no_products_found_icon_color}
        />
      )}
    </>
  );
};

export default ProductsList;
