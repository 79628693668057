import { FC, memo } from "react";
import { SearchBarProps } from "./types";
import { HeaderStyled } from "./styled";
import { DynamicSelector } from "..";

const SearchBar: FC<SearchBarProps> = ({
  searchBarConfig,
  selectorsConfig,
  setSearchTerm,
  databaseUrl,
  licenseKey,
  setData,
  vinLookupEnabled,
  licensePlateLookupEnabled,
  setTab,
  searchError,
  partTypeAllOption,
  store,
  collapsed,
  setCollapsed,
  handleShowFilters,
  partTypeEnabled,
  engineEbabled,
  handleClearSearchResult,
}) => {
  return (
    <HeaderStyled
      backgroundColor={searchBarConfig?.layout_background_color}
      className="search-bar-header-class"
    >
      {/* TODO */}
      {/* <HeaderTextWrapper>
        <HeaderText>Header</HeaderText>
      </HeaderTextWrapper> */}

      <DynamicSelector
        selectorsConfig={selectorsConfig}
        setSearchTerm={setSearchTerm}
        databaseUrl={databaseUrl}
        licenseKey={licenseKey}
        setData={setData}
        vinLookupEnabled={vinLookupEnabled}
        licensePlateLookupEnabled={licensePlateLookupEnabled}
        setTab={setTab}
        searchError={searchError}
        partTypeAllOption={partTypeAllOption}
        store={store}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        handleShowFilters={handleShowFilters}
        partTypeEnabled={partTypeEnabled}
        engineEbabled={engineEbabled}
        handleClearSearchResult={handleClearSearchResult}
      />
    </HeaderStyled>
  );
};

export default memo(SearchBar);
