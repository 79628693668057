import axios from "axios";
import { getSearchQuery } from "../common/helpers";
import {
  AddToCartData,
  FetchSMTPByPlateProps,
} from "../common/types/showMeThePartsTypes";
import axiosInstance from "./axiosInstance";

export const autoPartsSearch = async (
  domain: string | null,
  data: { [key: string]: string }
) => {
  const query = getSearchQuery(data);
  return axiosInstance
    .get(`/api/search?store=${domain}${query ? `&${query}` : ""}`)
    .catch((error) => {
      console.log("Error", error.message);
    });
};

export const autocompleteSearch = (domain: string, searchTerm: string) => {
  return axiosInstance.get(
    `/api/search/autocomplete?store=${domain}&q=${searchTerm}`
  );
};

export const fetchSelectorModalConfig = (store: string | null) =>
  axiosInstance.get(`/api/toolkit_assets/selector_modal_config?store=${store}`);

export const fetchProductsByLicensePlate = ({
  licensePlate,
  stateId,
}: {
  licensePlate: string;
  stateId: string;
}) => {
  return axiosInstance.get("/api/search/license_plate", {
    params: {
      license_plate: licensePlate,
      state_id: stateId,
    },
  });
};

export const fetchSMTPData = async ({
  url,
  id,
  licensePlate,
  stateId,
}: FetchSMTPByPlateProps) => {
  return axios.get(
    `https://${url}?lookup=plateapp&id=${id}&plateno=${licensePlate}&state=${stateId}`,
    {
      timeout: 3000,
    }
  );
};

export const addToCartRequest = async ({ formData }: AddToCartData) => {
  // @ts-ignore
  console.log(window.Shopify.routes.root, "shopify routes")
  // @ts-ignore
  return fetch(window.Shopify.routes.root + "cart/add.js", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
