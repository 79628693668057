import { styled } from "styled-components";
import { StyledProps } from "../../common/types";

export const Wrapper = styled.div`
  display: flex;
  align-items: end;
  width: 100%;

  > div {
    display: flex;
    align-items: end;
    width: 100%;

    &:first-of-type {
      margin-right: 20px;
    }
  }

  @media (max-width: 980px) {
    flex-direction: column;
    > div {
      &:first-of-type {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
`;
export const DropdownSectionStyled = styled.div`
  width: 100%;
  max-width: 1100px;
  display: grid;
  gap: 10px;
  grid-template-columns:
    minmax(110px, max-content) minmax(130px, max-content) minmax(
      110px,
      max-content
    )
    minmax(110px, 400px) minmax(110px, 1fr);
  @media (max-width: 980px) {
    gap: 5px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, max-content);
  }
`;

export const SearchButtonStyled = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px 16px !important;
  margin-left: 12px !important;
  border-radius: 8px !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "#1d4ed8"} !important;
  color: ${({ color }) => color || "#ffffff"} !important;
  font-size: ${({ fontSize }) => fontSize || "14px"} !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  width: 100px !important;
  min-width: fit-content !important;
  height: 40px !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 10em inset;
  }

  &:disabled {
    background-color: #cdcdcd !important;
    cursor: not-allowed !important;
    box-shadow: rgba(16, 24, 40, 0.12) 1px 1px 2px;
    &:hover {
      box-shadow: none;
    }
  }

  @media (max-width: 980px) {
    justify-content: center !important;
  }
`;
